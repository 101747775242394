var styles = {
  "CheckableButton": "Polaris-CheckableButton",
  "CheckableButton-measuring": "Polaris-CheckableButton__CheckableButton--measuring",
  "CheckableButton-plain": "Polaris-CheckableButton__CheckableButton--plain",
  "CheckableButton-selectMode": "Polaris-CheckableButton__CheckableButton--selectMode",
  "CheckableButton-selected": "Polaris-CheckableButton__CheckableButton--selected",
  "Checkbox": "Polaris-CheckableButton__Checkbox",
  "Label": "Polaris-CheckableButton__Label"
};

export default styles;
