var styles = {
  "FileUpload": "Polaris-DropZone-FileUpload",
  "FileUploadSmallView": "Polaris-DropZone-FileUpload__FileUploadSmallView",
  "Button": "Polaris-DropZone-FileUpload__Button",
  "pressed": "Polaris-DropZone-FileUpload--pressed",
  "disabled": "Polaris-DropZone-FileUpload--disabled",
  "focused": "Polaris-DropZone-FileUpload--focused",
  "sizeSlim": "Polaris-DropZone-FileUpload--sizeSlim",
  "ActionTitle": "Polaris-DropZone-FileUpload__ActionTitle",
  "ActionTitle-disabled": "Polaris-DropZone-FileUpload__ActionTitle--disabled",
  "ActionTitle-focused": "Polaris-DropZone-FileUpload__ActionTitle--focused"
};

export default styles;
