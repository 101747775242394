var styles = {
  "Navigation": "Polaris-Navigation",
  "UserMenu": "Polaris-Navigation__UserMenu",
  "ContextControl": "Polaris-Navigation__ContextControl",
  "PrimaryNavigation": "Polaris-Navigation__PrimaryNavigation",
  "LogoContainer": "Polaris-Navigation__LogoContainer",
  "Logo": "Polaris-Navigation__Logo",
  "LogoLink": "Polaris-Navigation__LogoLink",
  "Item": "Polaris-Navigation__Item",
  "keyFocused": "Polaris-Navigation--keyFocused",
  "Item-selected": "Polaris-Navigation__Item--selected",
  "Item-disabled": "Polaris-Navigation__Item--disabled",
  "Icon": "Polaris-Navigation__Icon",
  "Badge": "Polaris-Navigation__Badge",
  "subNavigationActive": "Polaris-Navigation--subNavigationActive",
  "ListItem": "Polaris-Navigation__ListItem",
  "RollupSection": "Polaris-Navigation__RollupSection",
  "SecondaryNavigation": "Polaris-Navigation__SecondaryNavigation",
  "ListItem-hasAction": "Polaris-Navigation__ListItem--hasAction",
  "ItemWrapper": "Polaris-Navigation__ItemWrapper",
  "Text": "Polaris-Navigation__Text",
  "SecondaryAction": "Polaris-Navigation__SecondaryAction",
  "isExpanded": "Polaris-Navigation--isExpanded",
  "List": "Polaris-Navigation__List",
  "SecondaryNavigation-noIcon": "Polaris-Navigation__SecondaryNavigation--noIcon",
  "Section": "Polaris-Navigation__Section",
  "Section-fill": "Polaris-Navigation__Section--fill",
  "Section-withSeparator": "Polaris-Navigation__Section--withSeparator",
  "SectionHeading": "Polaris-Navigation__SectionHeading",
  "Action": "Polaris-Navigation__Action",
  "RollupToggle": "Polaris-Navigation__RollupToggle",
  "Indicator": "Polaris-Navigation__Indicator",
  "fade-in": "Polaris-Navigation__fade--in"
};

export default styles;
