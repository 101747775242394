var styles = {
  "ProgressBar": "Polaris-ProgressBar",
  "sizeSmall": "Polaris-ProgressBar--sizeSmall",
  "sizeMedium": "Polaris-ProgressBar--sizeMedium",
  "sizeLarge": "Polaris-ProgressBar--sizeLarge",
  "colorHighlight": "Polaris-ProgressBar--colorHighlight",
  "colorPrimary": "Polaris-ProgressBar--colorPrimary",
  "colorSuccess": "Polaris-ProgressBar--colorSuccess",
  "Indicator": "Polaris-ProgressBar__Indicator",
  "Animated": "Polaris-ProgressBar__Animated",
  "fillup": "Polaris-ProgressBar--fillup",
  "Progress": "Polaris-ProgressBar__Progress",
  "Label": "Polaris-ProgressBar__Label"
};

export default styles;
