var styles = {
  "SingleThumb": "Polaris-RangeSlider-SingleThumb",
  "disabled": "Polaris-RangeSlider-SingleThumb--disabled",
  "InputWrapper": "Polaris-RangeSlider-SingleThumb__InputWrapper",
  "Prefix": "Polaris-RangeSlider-SingleThumb__Prefix",
  "Suffix": "Polaris-RangeSlider-SingleThumb__Suffix",
  "Input": "Polaris-RangeSlider-SingleThumb__Input",
  "error": "Polaris-RangeSlider-SingleThumb--error",
  "Output": "Polaris-RangeSlider-SingleThumb__Output",
  "OutputBubble": "Polaris-RangeSlider-SingleThumb__OutputBubble",
  "OutputText": "Polaris-RangeSlider-SingleThumb__OutputText"
};

export default styles;
