var styles = {
  "Tag": "Polaris-Tag",
  "disabled": "Polaris-Tag--disabled",
  "removable": "Polaris-Tag--removable",
  "clickable": "Polaris-Tag--clickable",
  "TagText": "Polaris-Tag__TagText",
  "Button": "Polaris-Tag__Button"
};

export default styles;
