var styles = {
  "Tabs": "Polaris-Tabs",
  "Wrapper": "Polaris-Tabs__Wrapper",
  "fitted": "Polaris-Tabs--fitted",
  "TabContainer": "Polaris-Tabs__TabContainer",
  "Title": "Polaris-Tabs__Title",
  "fillSpace": "Polaris-Tabs--fillSpace",
  "Tab": "Polaris-Tabs__Tab",
  "Tab-selected": "Polaris-Tabs__Tab--selected",
  "titleWithIcon": "Polaris-Tabs--titleWithIcon",
  "Panel": "Polaris-Tabs__Panel",
  "Panel-hidden": "Polaris-Tabs__Panel--hidden",
  "List": "Polaris-Tabs__List",
  "Item": "Polaris-Tabs__Item",
  "DisclosureTab": "Polaris-Tabs__DisclosureTab",
  "DisclosureTab-visible": "Polaris-Tabs__DisclosureTab--visible",
  "DisclosureActivator": "Polaris-Tabs__DisclosureActivator",
  "TabMeasurer": "Polaris-Tabs__TabMeasurer"
};

export default styles;
