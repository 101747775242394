var styles = {
  "Option": "Polaris-OptionList-Option",
  "SingleSelectOption": "Polaris-OptionList-Option__SingleSelectOption",
  "focused": "Polaris-OptionList-Option--focused",
  "active": "Polaris-OptionList-Option--active",
  "select": "Polaris-OptionList-Option--select",
  "disabled": "Polaris-OptionList-Option--disabled",
  "Media": "Polaris-OptionList-Option__Media",
  "Label": "Polaris-OptionList-Option__Label",
  "Checkbox": "Polaris-OptionList-Option__Checkbox"
};

export default styles;
