var styles = {
  "TopBar": "Polaris-TopBar",
  "LogoDisplayControl": "Polaris-TopBar__LogoDisplayControl",
  "LogoDisplayContainer": "Polaris-TopBar__LogoDisplayContainer",
  "LogoContainer": "Polaris-TopBar__LogoContainer",
  "Logo": "Polaris-TopBar__Logo",
  "LogoLink": "Polaris-TopBar__LogoLink",
  "ContextControl": "Polaris-TopBar__ContextControl",
  "NavigationIcon": "Polaris-TopBar__NavigationIcon",
  "focused": "Polaris-TopBar--focused",
  "Contents": "Polaris-TopBar__Contents",
  "SearchField": "Polaris-TopBar__SearchField",
  "SecondaryMenu": "Polaris-TopBar__SecondaryMenu"
};

export default styles;
